.page-card {
  .card-body {
    //padding: 1rem;
  }
  .card-footer {
    .card-button {
      cursor: pointer;
    }
    .card-button:hover {
      border-radius: calc(0.375rem - 1px);
      background-color: #f8f9fe;
      border: none !important;
    }
  }
  .photo-container {
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    margin-left: 1rem;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .info-container {

  }
}