// custom sweet alert modal
.custom-alert-modal, .custom-loading-modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 5000;
    background-color: rgba(0, 0, 0, 0.16);
    .sweet-alert {
        position: relative !important;
        margin: 0px !important;
        top: auto !important;
        left: auto !important;
        right: auto !important;
        bottom: auto !important;
    }
    @media(max-width: 414px) {
        .sweet-alert {
            width: 330px !important;
            margin: 0px !important;
        }   
    }
    @media(max-width: 320px) {
        .sweet-alert {
            width: 300px !important;
            margin: 0px !important;
        }   
    }
    circle {
        fill: #0082ca;
    }
}
.custom-alert-modal > div > div {
    display: none;
}
.custom-loading-modal {
    .custom-loading-modal-content {
        background-color: #fff;
        border-radius: 8px;
        padding: 32px 40px 18px 40px;
        p {
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            margin: 18px 0px 0px 0px;
        }
    }
}
