html, body, #root {
  width: 100%;
  height: 100%;
}

.public-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.public-page {
  display: flex;
  flex-direction: column;
  padding: 0px;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  height: 100%;
  position: relative;
  .inner-container {
    overflow: auto;
    height: 100%;
    background-color: #fff;
  }
  .card-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 100%;
    background-color: transparent;
  }
  .card-body {
    background-color: #fff;
  }
  .photo-row {
    margin-top: -34px;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .photo-container {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-top: -50px;
    border-radius: 50%;
    // border: 3px solid #fff;
    position: relative;
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .preview-footer {
    display: flex;
    justify-content: center;
    padding: 12px;
    cursor: pointer;
    img {
      height: 30px;
    }
  }
}

@media (min-width: 760px) {
  .public-page {
    max-width: 414px;
    max-height: 736px;
  }
}