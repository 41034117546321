.editor-view {
  //background-color: #fff;
  height: 100%;
  overflow: hidden;

  > div {
    //border-right: 1px solid #e2e2e2;
  }
}

.editor-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  background-color: #fff;

  .editor-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    padding: 8px;
    cursor: pointer;
    margin-right: 14px;

    span {
      position: absolute;
      bottom: 0px;
      width: 0px;
      height: 2px;
      background-color: transparent;
      transition: 0.3s width;
    }

    &.active {
      span {
        width: 100%;
        background-color: #000 !important;
      }
    }

    p {
      margin: 0px;
      font-size: 14px;
      font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .editor-tab:hover {
    span {
      width: 100%;
      background-color: #e2e2e2;
    }
  }

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    a {
      
    }
  }
}

.links-container {
  height: 667px;
  min-height: 667px;
  max-height: 667px;
  > .card-header {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  > .card-body {
    overflow: auto;
  }
}

.card-link {
  display: flex;
  border: 1px solid #efefef;
  box-shadow: 0 4px 8px 0 rgba(136, 152, 170, 0.15);

  &.with-handle {
    flex-direction: row;
  }
  &.dragging {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.45);
  }
  .card-top {
    display: flex;
    justify-content: center;
    height: 160px;
    width: 100%;
    padding: 30px 30px 60px 30px;
    img {
      height: 40px;
    }
  }
  .card-handle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
  }
  .card-body {
    padding: 0.75rem 0.75rem 0.5rem 0.75rem;
    .form-group {
      margin-bottom: 0.6rem;
      .form-control {
        padding: 0.5rem 0.6rem;
        height: calc(2.25rem + 2px);
      }
    }
  }
  .outline-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid;
  }
  .card-footer {
    display: flex;
    justify-content: center;
    height: 160px;
    width: 100%;
    padding: 30px 30px 60px 30px;
    img {
      height: 40px;
    }
  }
  .info-container {
    
  }
  .link-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    i {
      // color: red;
      font-size: 14px;
    }
  }
  .link-button:hover {
    border-radius: calc(0.375rem - 1px);
    background-color: #f8f9fe;
    border: none !important;
  }
}

.phone-frame {
  position: absolute;
  width: 375px;
  min-width: 375px;
  max-width: 375px;
  height: 667px;
  min-height: 667px;
  max-height: 667px;
  border-radius: 40px;
  border: 14px solid #000;
  margin: auto;
  pointer-events: none;
}

.card-preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 375px;
  min-width: 375px;
  max-width: 375px;
  height: 667px;
  min-height: 667px;
  max-height: 667px;
  border-radius: 40px;
}

.card-preview {
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  height: 100%;
  position: relative;
  .inner-container {
    overflow: auto;
    height: 100%;
    background-color: #fff;
  }
  .card-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 110px;
    width: 100%;
    background-color: transparent;
  }
  .card-body {
    background-color: #fff;
  }
  .photo-row {
    margin-top: -24px;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }
  .photo-container {
    margin-top: -60px;
  }
  .preview-footer {
    display: flex;
    justify-content: center;
    padding: 12px;
    cursor: pointer;
    img {
      height: 30px;
    }
  }
}

.photo-container {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  // border: 3px solid #fff;
  position: relative;
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .edit-photo-button {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.3s;
    i {
      font-size: 14px;
      margin-right: 4px;
    }
  }
}
.photo-container:hover {
  .edit-photo-button {
    opacity: 1;
  }
}